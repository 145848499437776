import {BlockData} from './state';
import {PageBlocksState} from './state';
import {createInitialState} from './state';

/**
 * RESET
 *
 * @param state
 */
export function RESET(state: PageBlocksState) {
  Object.assign(state, createInitialState());
}

/**
 * SET_BLOCKS
 *
 * @param state
 * @param value
 */
export function SET_BLOCKS(state: PageBlocksState, value: BlockData[]) {
  state.blocks = value;
}

/**
 * RESET_BLOCKS
 *
 * @param state
 */
export function RESET_BLOCKS(state: PageBlocksState) {
  state.blocks = createInitialState().blocks;
}

/**
 * SET_CURRENT_BLOCK_INDEX
 *
 * @param state
 * @param value
 */
export function SET_CURRENT_BLOCK_INDEX(state: PageBlocksState, value: number) {
  state.currentBlockIndex = value;
}

/**
 * RESET_CURRENT_BLOCK_INDEX
 *
 * @param state
 */
export function RESET_CURRENT_BLOCK_INDEX(state: PageBlocksState) {
  state.currentBlockIndex = createInitialState().currentBlockIndex;
}

import {ref} from 'vue';

/**
 * Page header controller.
 */
export class PageHeaderController {
  /**
   * Is header visible.
   */
  readonly visibleRef = ref(true);

  /**
   * Audio playing.
   */
  readonly audioPlayingRef = ref(false);

  /**
   * Hide.
   */
  hide() {
    this.visibleRef.value = false;
  }

  /**
   * Show.
   */
  show() {
    this.visibleRef.value = true;
  }

  /**
   * Stop background audio.
   */
  stopBackgroundAudio() {
    const audioEls = document.getElementsByClassName('background-audio');
    for (const el of audioEls) {
      const audio = el as HTMLAudioElement;
      audio.muted = true;
      audio.pause();
    }
    const videoEls = document.getElementsByClassName('background-video');
    for (const el of videoEls) {
      const video = el as HTMLAudioElement;
      video.muted = true;
    }
    this.audioPlayingRef.value = false;
  }

  /**
   * Play background audio.
   */
  playBackgroundAudio() {
    const audioEls = document.getElementsByClassName('background-audio');
    for (const el of audioEls) {
      const audio = el as HTMLAudioElement;
      audio.muted = false;
      audio.play();
    }
    const videoEls = document.getElementsByClassName('background-video');
    for (const el of videoEls) {
      const video = el as HTMLVideoElement;
      video.muted = false;
    }
    this.audioPlayingRef.value = true;
  }
}

import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  loop: "",
  muted: "",
  autoplay: "",
  ref: "video",
  class: "background-video"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", _hoisted_1, [
    _createElementVNode("source", {
      src: _ctx.url,
      type: "video/mp4"
    }, null, 8, _hoisted_2),
    _createTextVNode(" Your browser does not support the video tag. ")
  ], 512))
}
import {LangObject} from '@/types/index';

/**
 * Main page block type.
 */
export enum MainPageBlockType {
  SIDED_TITLED_TEXT = 'main-page-sided-titled-text',
  CONTACTS = 'main-page-contacts',
}

/**
 * Main page block frames map.
 */
export type MainPageBlockFramesMap = {
  enterFrom: number;
  loopFrom: number;
  loopTo: number;
}

/**
 * Main page sided titled text block data.
 */
export type MainPageSidedTitledTextBlockData = {
  id: string,
  type: MainPageBlockType.SIDED_TITLED_TEXT,
  title: LangObject,
  description: LangObject,
  framesUrlTemplate: string,
  framesMap: MainPageBlockFramesMap,
  blockType: string,
  textColor?: string,
}

/**
 * Main page contacts block data.
 */
export type MainPageContactsBlockData = {
  id: string,
  type: MainPageBlockType.CONTACTS,
  title: LangObject,
  description: LangObject,
  framesUrlTemplate: string,
  framesMap: MainPageBlockFramesMap,
  elements: MainPageContactsElement[],
}

/**
 * Main page contacts element.
 */
export type MainPageContactsElement = {
  title: LangObject,
  description: LangObject,
}

/**
 * Main page block data.
 */
export type MainPageBlockData =
  | MainPageSidedTitledTextBlockData
  | MainPageContactsBlockData;

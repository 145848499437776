import {createInitialState} from './state';

import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const globalModule = {
  namespaced: true,
  state: createInitialState,
  getters,
  actions,
  mutations,
}

import {GlobalState} from './state';
import {Locale} from '@/store/global/state';
import {ActionContext} from 'vuex';
import {StoreState} from '@/store';

/**
 * Context.
 */
type Context = ActionContext<GlobalState, StoreState>;

/**
 * TOGGLE_LOCALE
 *
 * @param state
 * @param commit
 * @constructor
 */
export function TOGGLE_LOCALE({state, commit}: Context) {
  if (state.locale === Locale.RU) {
    commit('SET_LOCALE', Locale.EN);
  } else {
    commit('SET_LOCALE', Locale.RU);
  }
  return state;
}

import Vuex from 'vuex';
import {GlobalState} from './global/state';
import {globalModule} from './global/index';
import {PageBlocksState} from './page-blocks/state';
import {pageBlocksModule} from './page-blocks/index';

/**
 * Store state.
 */
export type StoreState = {
  global: GlobalState;
  pageBlocks: PageBlocksState;
}

/**
 * Create store.
 */
export function createStore() {
  return Vuex.createStore<StoreState>({
    modules: {
      global: globalModule,
      pageBlocks: pageBlocksModule,
    },
  });
}

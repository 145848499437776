<template>
  <video
    loop
    muted
    autoplay
    ref="video"
    class="background-video">
    <source :src="url" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</template>

<script lang="ts">
  import {defineComponent} from 'vue';
  import {ref} from 'vue';
  import {onMounted} from 'vue';
  import {services} from '@/services/service-container';
  import {PageHeaderController} from '@/services/page-header-controller';
  import {onBeforeUnmount} from 'vue';

  export default defineComponent({
    name: 'background-video',
    props: {
      url: {
        type: String,
        required: true,
      },
    },
    setup() {
      const video = ref<HTMLVideoElement>();
      const controller = services.get(PageHeaderController)
      const onUpdate = () => {
        if (!video.value) throw new Error('The HTMLVideoElement is not found.');
        const time = video.value.currentTime;
        // console.log(video.value.currentTime);
        if (controller.visibleRef.value) {
          if (time >= 0 && time <= 3) {
            controller.visibleRef.value = false;
          }
        } else {
          if (time > 3) {
            controller.visibleRef.value = true;
          }
        }
      }
      onMounted(() => {
        if (!video.value) throw new Error('The HTMLVideoElement is not found.');
        video.value.addEventListener('timeupdate', onUpdate, false);
      });
      onBeforeUnmount(() => {
        if (!video.value) throw new Error('The HTMLVideoElement is not found.');
        video.value?.removeEventListener('timeupdate', onUpdate);
      });
      return {video};
    },
  });
</script>

<style lang="scss">
  .background-video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
</style>

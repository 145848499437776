import {StoreState} from '@/store';
import {ActionContext} from 'vuex';
import {PageBlocksState} from '@/store/page-blocks/state';
import {MainPageBlockData} from '@/types/main-page-block-data';

/**
 * Context.
 */
type Context = ActionContext<PageBlocksState, StoreState>;

/**
 * RESET_STATE_AND_SET_PAGE_BLOCKS
 *
 * @param state
 * @param commit
 * @param payload
 */
export function RESET_STATE_AND_SET_PAGE_BLOCKS(
  {state, commit}: Context,
  payload: MainPageBlockData[],
) {
  commit('RESET');
  commit('SET_PAGE_BLOCKS', payload);
  return state;
}

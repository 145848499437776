<template>
  <div
    @click="toggleLocale"
    class="locale-toggle-button">
    {{ locale }}
  </div>
</template>

<script lang="ts">
  import {mapState} from 'vuex';
  import {mapActions} from 'vuex';
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: 'locale-toggle-button',
    computed: {
      ...mapState('global', {
        locale: 'locale',
      }),
    },
    methods: {
      ...mapActions('global', {
        toggleLocale: 'TOGGLE_LOCALE',
      }),
    },
  });
</script>

<style lang="scss">
  .locale-toggle-button {
    color: inherit;
  }
</style>

import DebugFactory from 'debug';
import {format} from '@e22m4u/js-format';

/**
 * Debug factory.
 *
 * @param name
 */
export const debugFactory = function (
  name: string,
): (p: string, ...args: unknown[]) => void {
  const debug = DebugFactory(`patiss:${name}`);
  return function (pattern: string, ...args: unknown[]) {
    if (process.env.VUE_APP_DEBUG)
      DebugFactory.enable(process.env.VUE_APP_DEBUG);
    const message = format(pattern, ...args);
    return debug(message);
  };
};

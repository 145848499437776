import {LangObject} from '@/types';

/**
 * Block type.
 */
export enum BlockType {
  MAIN_PAGE_SIDED_TITLED_TEXT = 'sidedTitledTextWithAnimatedBackground',
  MAIN_PAGE_CONTACTS = 'contactsWithAnimatedBackground',
}

/**
 * Block data.
 */
export type BlockData =
  | SidedTitledTextWithAnimatedBackground
  | ContactsWithAnimatedBackground;

/**
 * Sided titled text with animated background.
 */
export type SidedTitledTextWithAnimatedBackground = {
  type: BlockType.MAIN_PAGE_SIDED_TITLED_TEXT,
  title: LangObject,
  description: LangObject,
  framesUrlTemplate: string,
  framesMap: BlockFramesMap,
}

/**
 * Contacts with animated background.
 */
export type ContactsWithAnimatedBackground = {
  type: BlockType.MAIN_PAGE_CONTACTS,
  title: LangObject,
  description: LangObject,
  framesUrlTemplate: string,
  framesMap: BlockFramesMap,
  elements: ContactsWithAnimatedBackgroundElement[],
}

/**
 * Contacts with animated background element.
 */
export type ContactsWithAnimatedBackgroundElement = {
  title: LangObject,
  description: LangObject,
}

/**
 * Block frames map.
 */
export type BlockFramesMap = {
  enterFrom: number;
  loopFrom: number;
  loopTo: number;
}

/**
 * Page blocks state.
 */
export interface PageBlocksState {
  blocks: BlockData[];
  currentBlockIndex: number;
}

/**
 * Create initial state.
 */
export function createInitialState(): PageBlocksState {
  return {
    blocks: [],
    currentBlockIndex: 0,
  };
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAudio && _ctx.toggleAudio(...args))),
    class: "audio-toggle-button"
  }, [
    _createElementVNode("img", {
      alt: "logo",
      src: _ctx.iconSrc,
      class: "page-header__audio"
    }, null, 8, _hoisted_1)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_background_video = _resolveComponent("background-video")!
  const _component_page_header = _resolveComponent("page-header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_background_video, { url: "/assets/video/background-stub.mp4" }),
    _createVNode(_component_page_header)
  ]))
}
/**
 * To camel case.
 *
 * @param str
 */
export function toCamelCase(str: string) {
  return str
    .replace(/(^\w|[A-Z]|\b\w)/g, c => c.toUpperCase())
    .replace(/\W+/g, '')
    .replace(/(^\w)/g, c => c.toLowerCase());
}

import {MainPageBlockType} from '@/types';
import {MainPageBlockData} from '@/types';

/**
 * Main page blocks.
 */
export const mainPageBlocks: MainPageBlockData[] = [
  {
    id: '6616e3db29118c95360d7e38',
    type: MainPageBlockType.SIDED_TITLED_TEXT,
    title: {
      ru: "Экспертиза\nна молекулярном\nуровне",
      en: "Molecular level\nof expertise"
    },
    description: {
      ru: "Высокий уровень компетенций на всех этапах создания успешной рекламной коммуникации. Мы собрали команду профессионалов, чтобы стать вашим надежным партнером.",
      en: "High level of competencies at all stages of creating successful advertising communications. We have assembled a team of professionals to become your reliable partner."
    },
    framesUrlTemplate: "/assets/images/test/{fileIndex}.webp",
    framesMap: {
      enterFrom: 1,
      loopFrom: 90,
      loopTo: 864
    },
    blockType: 'center',
    textColor: '#F7FBFC',
  },
  {
    id: '6616e3db29118c95360d7e39',
    type: MainPageBlockType.SIDED_TITLED_TEXT,
    title: {
      ru: "Креатив",
      en: "Creative"
    },
    description: {
      ru: "Яркие решения, нестандартный взгляд, уникальные идеи привлекут внимание к вашему контенту и позволят выделить среди ваших конкурентов.",
      en: "Bright solutions, unconventional views, unique ideas will attract attention to your content and allow you to stand out from your competitors."
    },
    framesUrlTemplate: "/assets/images/main-frames/{fileIndex}.webp",
    framesMap: {
      enterFrom: 151,
      loopFrom: 183,
      loopTo: 224
    },
    blockType: 'left',
  },
  {
    id: '6616e3db29118c95360d7e40',
    type: MainPageBlockType.SIDED_TITLED_TEXT,
    title: {
      ru: "Диджитал",
      en: "Digital"
    },
    description: {
      ru: "Цифровая коммуникация – современный способ воздействия на аудиторию через электронные устройства. Позволяет охватить большую базу потенциальных клиентов, собрать информацию об их предпочтениях, отслеживать и измерять эффективность рекламной компании.",
      en: "Digital communication is a modern way of influencing an audience through electronic devices. Allows you to reach a large base of potential clients, collect information about their preferences, track and measure the effectiveness of an advertising campaign."
    },
    framesUrlTemplate: "/assets/images/main-frames/{fileIndex}.webp",
    framesMap: {
      enterFrom: 225,
      loopFrom: 254,
      loopTo: 294
    },
    blockType: 'right',
  },
  {
    id: '6616e3db29118c95360d7e41',
    type: MainPageBlockType.SIDED_TITLED_TEXT,
    title: {
      ru: "Видео",
      en: "Video"
    },
    description: {
      ru: "Видео – современный, привлекательный и наглядный способ привлечения внимания к бренду. Это наиболее эмоциональный способ рассказать свою историю, которая запомнится и изменит отношение зрителя к бренду. Лучше один раз увидеть и услышать, чем сто раз прочитать.",
      en: "Video is a modern, attractive and visual way to attract attention to a brand. This is the most emotional way to tell your story, which will be remembered and change the viewer’s attitude towards the brand. It’s better to see and hear once than to read a hundred times."
    },
    framesUrlTemplate: "/assets/images/main-frames/{fileIndex}.webp",
    framesMap: {
      enterFrom: 295,
      loopFrom: 332,
      loopTo: 378
    },
    blockType: 'left',
  },
  {
    id: '6616e3db29118c95360d7e42',
    type: MainPageBlockType.SIDED_TITLED_TEXT,
    title: {
      ru: "Медицинская\nэкспертиза",
      en: "Medical expertise"
    },
    description: {
      ru: "Видео – современный, привлекательный и наглядный способ привлечения внимания к бренду. Это наиболее эмоциональный способ рассказать свою историю, которая запомнится и изменит отношение зрителя к бренду. Лучше один раз увидеть и услышать, чем сто раз прочитать.",
      en: "Video is a modern, attractive and visual way to attract attention to a brand. This is the most emotional way to tell your story, which will be remembered and change the viewer’s attitude towards the brand. It’s better to see and hear once than to read a hundred times."
    },
    framesUrlTemplate: "/assets/images/main-frames/{fileIndex}.webp",
    framesMap: {
      enterFrom: 379,
      loopFrom: 416,
      loopTo: 460
    },
    blockType: 'right',
  },
  {
    id: '6616e3db29118c95360d7e43',
    type: MainPageBlockType.CONTACTS,
    title: {
      ru: "КОНТАКТЫ",
      en: "CONTACTS"
    },
    description: {
      ru: "",
      en: ""
    },
    framesUrlTemplate: "/assets/images/contacts-frames/{fileIndex}.webp",
    framesMap: {
      enterFrom: 1,
      loopFrom: 60,
      loopTo: 105
    },
    elements: [
      {
        title: {
          ru: "МЫ НАХОДИМСЯ",
          en: "LOCATION"
        },
        description: {
          ru: "121357 г. Москва, ул. Верейская, 17 БЦ «Верейская Плаза» 4 этаж, офис 402",
          en: "121357, Moscow17 Vereyskaya Street, Moscow, Business center “Vereyskaya Plaza”, 4th floor,office 402."
        }
      },
      {
        title: {
          ru: "ГРАФИК РАБОТЫ",
          en: "WORK SCHEDULE"
        },
        description: {
          ru: "Понедельник–пятница\n10:00–18:30",
          en: "Monday–Friday\n10:00-18:30"
        }
      },
      {
        title: {
          ru: "КОНТАКТЫ",
          en: "CONTACTS"
        },
        description: {
          ru: "+7 (499) 372 51 82\ninfo@patiss.ru",
          en: "7 (499) 372 51 82\ninfo@patiss.ru"
        }
      }
    ]
  }
];

import App from './app.vue';
import {createApp} from 'vue';
import {createStore} from './store';
import * as plugins from './plugins';

import 'animate.css';
import './assets/scss/main.scss';

const app = createApp(App);
app.use(createStore());
Object.values(plugins).forEach(plugin => app.use(plugin));

app.mount('#app');

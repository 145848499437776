import {Locale} from './state';
import {GlobalState} from './state';
import {createInitialState} from './state';

/**
 * SET_LOCALE
 *
 * @param state
 * @param value
 */
export function SET_LOCALE(state: GlobalState, value: Locale) {
  state.locale = value;
}

/**
 * RESET_LOCALE
 *
 * @param state
 */
export function RESET_LOCALE(state: GlobalState) {
  state.locale = createInitialState().locale;
}

/**
 * Preload image.
 *
 * @param src
 */
export function preloadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = function() {
      resolve(image);
    }
    image.onerror = reject
    image.src = src
  });
}

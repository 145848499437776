import {useStore} from 'vuex';
import {LangObject} from '@/types';
import {StoreState} from '@/store';

/**
 * Localize.
 *
 * @param input
 */
export function localize(input: LangObject | string | undefined) {
  if (input == null) return '';
  if (typeof input === 'string') return input;
  const store = useStore<StoreState>();
  return input[store.state.global.locale];
}

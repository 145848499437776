import {toCamelCase} from '@/utils';
import {debugFactory} from '@/utils';
import {ServiceContainer} from '@e22m4u/js-service';
import {Service as BaseService} from '@e22m4u/js-service';

/**
 * Service.
 */
export class Service extends BaseService {
  /**
   * Debug.
   */
  protected readonly debug: ReturnType<typeof debugFactory>;

  /**
   * Constructor.
   *
   * @param container
   */
  constructor(container?: ServiceContainer) {
    super(container);
    const serviceName = toCamelCase(this.constructor.name);
    this.debug = debugFactory(serviceName);
    this.debug('The %v has created.', this.constructor);
  }
}

<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn animate__faster"
    leave-active-class="animate__animated animate__fadeOut animate__faster">
    <div
      v-show="visible"
      class="page-header">
      <img
        alt="logo"
        class="page-header__logo"
        src="/assets/images/logo/logo_patiss_colored.svg"/>
      <div
        class="page-header__spacer"/>
      <audio-toggle-button
        class="page-header__btn"/>
      <locale-toggle-button
        v-if="false"
        class="page-header__btn"/>
    </div>
  </transition>
</template>

<script lang="ts">
  import {defineComponent} from 'vue';
  import {PageHeaderController} from '@/services';
  import {services} from '@/services/service-container';
  import AudioToggleButton from '@/components/page-header/audio-toogle-button.vue';
  import LocaleToggleButton from '@/components/page-header/locale-toggle-button.vue';

  export default defineComponent({
    name: 'page-header',
    components: {
      LocaleToggleButton,
      AudioToggleButton,
    },
    setup() {
      const visible = services.get(PageHeaderController).visibleRef;
      return {visible};
    },
  });
</script>

<style lang="scss">
  .page-header {
    left: 0;
    right: 0;
    display: flex;
    position: absolute;
    color: $secondaryTextColor;
    @include mq('height', $pageHeaderHeight);
    @include mq('padding-left', $pageXOffset);
    @include mq('padding-right', $pageXOffset);

    &__logo {
      @include mq('width', 15rem);
    }

    &__spacer {
      flex: 1;
    }

    &__btn {
      display: flex;
      cursor: pointer;
      padding: 0 1rem;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      @include mq('font-size', 1.4rem);
      @include mq('padding-left', 1.2rem);
      @include mq('padding-right', 1.2rem);
    }

    &__audio {
      width: 3rem;
      height: 3rem;
    }
  }
</style>

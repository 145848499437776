<template>
  <div class="app">
<!--    <main-page-blocks-->
<!--      :page-blocks="mainPageBlocks"/>-->
    <background-video
      url="/assets/video/background-stub.mp4"/>
    <page-header/>
<!--    <background-audio-->
<!--      url="/assets/audio/main-page.mp3"/>-->
  </div>
</template>

<script lang="ts">
  import {defineComponent} from 'vue';
  import {PageHeaderController} from '@/services';
  import {services} from '@/services/service-container';
  import {mainPageBlocks} from './constants/main-page-blocks';
  import PageHeader from '@/components/page-header/page-header.vue';
  import BackgroundVideo from '@/components/video/background-video.vue';
  // import BackgroundAudio from '@/components/audio/background-audio.vue';
  // import MainPageBlocks from './components/main-page-blocks/main-page-blocks.vue';

  export default defineComponent({
    name: 'App',
    components: {
      BackgroundVideo,
      PageHeader,
      // MainPageBlocks,
      // BackgroundAudio,
    },
    setup() {
      services.get(PageHeaderController).hide();
      return {mainPageBlocks};
    },
  });
</script>

<style>
  .app {
    color: inherit;
  }
</style>

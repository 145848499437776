/**
 * Locale.
 */
export enum Locale {
  RU = 'ru',
  EN = 'en',
}

/**
 * State.
 */
export interface GlobalState {
  locale: Locale;
}

/**
 * Create initial state.
 */
export function createInitialState(): GlobalState {
  return {
    locale: Locale.RU,
  };
}

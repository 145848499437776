<template>
  <div
    @click="toggleAudio"
    class="audio-toggle-button">
    <img
      alt="logo"
      :src="iconSrc"
      class="page-header__audio"/>
  </div>
</template>

<script lang="ts">
  import {computed} from 'vue';
  import {defineComponent} from 'vue';
  import {PageHeaderController} from '@/services';
  import {services} from '@/services/service-container';

  export default defineComponent({
    name: 'audio-toggle-button',
    setup() {
      const controller = services.get(PageHeaderController);
      const iconSrc = computed(() => {
        if (controller.audioPlayingRef.value) {
          return '/assets/images/icons/audio_on.svg';
        } else {
          return '/assets/images/icons/audio_off.svg';
        }
      });
      const toggleAudio = () => {
        if (controller.audioPlayingRef.value) {
          controller.stopBackgroundAudio();
        } else {
          controller.playBackgroundAudio();
        }
      }
      return {
        iconSrc,
        toggleAudio,
        playing: controller.audioPlayingRef,
      };
    }
  });
</script>

<style lang="scss">
  .audio-toggle-button {
    color: inherit;

    & img {
      @include mq('width', 3rem);
    }
  }
</style>
